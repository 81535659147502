import { defineComponent } from 'vue';
import LogComponent from '@/core/components/notifications/LogComponent.vue';
import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
import EmptyState from '@/core/components/ui/EmptyState.vue';
export default defineComponent({
    components: {
        LogComponent,
        DefaultCardSlot,
        EmptyState
    },
    emits: ['handle-click'],
    props: {
        title: {
            type: String
        },
        data: {
            type: Array,
            default: () => []
        },
        loadingData: {
            type: Boolean,
            default: true
        },
        hasShadow: {
            type: Boolean,
            default: true,
        },
        cardHeight: {
            type: String,
            default: '397px'
        },
        isClickable: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        handleClick(event) {
            this.$emit('handle-click', event);
        }
    },
});

<template>
    <el-card>
        <div class="flex-column">
           <!-- <div class="flex-row ai-c jc-sb">
               <EditableCardDataItem :property="property" v-for="(item, index) in houseDetails" :key="index" :data="item"/>
           </div> -->
            <div class="flex-column">
                <div class="flex-row ai-c jc-sb">
                    <p class="default-text">Property type</p>
                    <p class="detail default-text" v-if="property && property.propertyType && property.propertyType !== null">{{property.propertyType}}</p>
                    <p class="detail default-text" v-else-if="property.propertyType === null && !property.propertyType && getProperty && getProperty['property-type']">{{getProperty && getProperty['property-type']}}</p>
                    <p v-else class="detail default-text"> N/A </p>
                </div>
                <div class="flex-row ai-c jc-sb">
                    <p class="default-text">Size</p>
                    <p class="detail default-text" v-if="property && property.propertySize && property.propertySize !== null">{{property.propertySize}} {{PROPERTY_SIZE_UNIT}}</p>
                    <p v-else-if="property.propertySize === null && !property.propertySize && getProperty && getProperty['total-floor-area']" class="detail flex-row ai-c jc-c default-text">{{getProperty['total-floor-area']}} {{PROPERTY_SIZE_UNIT}}</p>
                    <p v-else class="detail default-text"> N/A </p>
                </div>
                <div class="flex-row ai-c jc-sb">
                    <p class="default-text">Tenure</p>
                    <p class="detail default-text" v-if="property && property.tenure && property.tenure !== null">{{property.tenure}}</p>
                    <p class="detail flex-row ai-c jc-c default-text" v-else-if="property.tenure === null && !property.tenure && getProperty && getProperty['tenure']">{{getProperty['tenure']}}</p>
                    <p v-else class="detail default-text"> N/A </p>
                </div>
                <div class="flex-row ai-c jc-sb">
                    <p class="default-text">Carbon footprint</p>
                    <div class="cta flex-row ai-c">
                       <p v-if="getProperty && getProperty['co2-emissions-current']" class="detail flex-row ai-c jc-c default-text">{{getProperty['co2-emissions-current']}} t of CO2</p>
                        <p v-else class="detail default-text"> N/A </p>
                        <!-- <el-icon :size="15"><ArrowRightBold/></el-icon> -->
                    </div>
                </div>
                <div class="flex-row ai-c jc-sb">
                    <p class="default-text">EPC certificate <span>(Expiry)</span></p>
                    <div class="cta flex-row ai-c">
                      <p class="detail flex-row ai-c jc-c default-text">11/11/2028</p>
                    </div>
                </div>
                <div class="flex-row ai-c jc-sb">
                    <p class="default-text">Energy rating</p>
                    <div class="cta flex-row ai-c" @click="getProperty && getProperty['current-energy-efficiency'] && setOpenEnergyEfficiencyRatingModalfalse(true)">
                      <p v-if="getProperty && getProperty['current-energy-efficiency']" class="detail flex-row ai-c jc-c default-text">{{getProperty['current-energy-efficiency']}}&nbsp; |&nbsp; {{getProperty['current-energy-rating']}}</p>
                      <p v-else class="detail default-text"> N/A </p>
                      <el-icon :size="15" color="#FFA500" v-if="getProperty && getProperty['current-energy-efficiency']" ><ArrowRightBold/></el-icon>
                    </div>
                </div>
                <!-- <div class="flex-row space-between">
                    <p class="default-text">Energy efficiency</p>
                    <div class="cta flex-row">
                       <p v-if="getProperty && getProperty['current-energy-efficiency']" class="detail">{{getProperty['current-energy-efficiency']}}</p>
                        <p v-else class="detail"> - </p>
                    </div>
                </div> -->
                <div class="edit-details-container flex-row ai-c jc-c">
                  <p class="edit-cta default-text cursor-pointer" v-if="canEdit" @click="setOpenEditPropertyDetailsModal(true)">Edit details</p>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <EnergyEfficiencyRatingModal :property="property" :show="getOpenEnergyEfficiencyRatingModalfalse" @on-confirm="setOpenEnergyEfficiencyRatingModalfalse(false)" @on-cancel="setOpenEnergyEfficiencyRatingModalfalse(false)"/>
        <EnergyEfficiencyModal :show="getOpenEnergyEfficiencyModal" @close="setOpenEnergyEfficiencyModal(false)"/>
    </el-card>
</template>
<script>
import { ArrowRightBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

// import EditableCardDataItem from '@/modules/property-details-children/components/EditableCardDataItem.vue';
import EnergyEfficiencyModal from '@/modules/property-details-children/components/EnergyEfficiencyModal.vue';
import EnergyEfficiencyRatingModal from '@/modules/property-details-children/components/modal/EnergyEfficiencyRatingModal.vue';
import { PROPERTIES_STORE } from '@/store/modules/properties';
import { USERS_STORE } from '@/store/modules/users';

const PROPERTY_SIZE_UNIT = 'sqm';

export default defineComponent({
  components: {
    // EditableCardDataItem,
    ArrowRightBold,
    EnergyEfficiencyRatingModal,
    EnergyEfficiencyModal
  },
  data() {
    return {
      houseDetails: [
        /* eslint-disable global-require */
        {
          icon: `${require('@/assets/icons/bed-icon.svg')}`, name: 'beds'
        },
        {
          icon: `${require('@/assets/icons/bath-icon.svg')}`, name: 'baths'
        },
        {
          icon: `${require('@/assets/icons/reception-icon.svg')}`, name: 'reception'
        },
        /* eslint-disable global-require */
      ],
      PROPERTY_SIZE_UNIT
    };
  },
  props: {
    height: {
      type: String,
      default: '330px'
    },
    property: {
      type: Object,
      required: false
    }
  },
  created() {
  },
  computed: {
    ...mapGetters(PROPERTIES_STORE, ['getOpenEnergyEfficiencyRatingModalfalse', 'getOpenEnergyEfficiencyModal']),

    ...mapGetters(USERS_STORE, ['wsRole', 'isWsViewer']),

    canEdit() {
      const { wsRole, isWsViewer } = this;
      let show = true;

      if (wsRole && isWsViewer) {
        show = !show;
      }

      return show;
    },

    getProperty() {
      if (this.property) {
        return this.property.epcData;
      }
      return null;
    }
  },
  methods: {
    ...mapActions(PROPERTIES_STORE, ['setOpenEnergyEfficiencyModal', 'setOpenEditPropertyDetailsModal', 'setOpenEnergyEfficiencyRatingModalfalse']),
  }
});
</script>
<style lang="scss" scoped>
.el-card.is-always-shadow {
    box-shadow: 0px 0px 20px rgba(12, 15, 74, 0.1);
}
.el-card {
    width: 100%;
    max-height: v-bind('$props.height');
    height: v-bind('$props.height');
    box-sizing: border-box;
    position: relative;
    border-radius: 30px;
    border: none;

    :deep(.el-card__body) {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 30px;
    }
}
.default-text {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.25px;
    text-align: left;
    line-height: 20px;

    span {
      font-size: 10px;
      color: rgba(12, 15, 74, 0.5);
    }
}
.cta {
  gap: .5rem;
}
.detail {
    gap: .5rem;
    @extend .default-text;
    text-align: right;
    color:rgba(12, 15, 74, 0.5);
}
.cta {
  cursor: pointer;
}
.edit-details-container {
  width: 100%;
}
.edit-cta {
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.25px;
  color: #FFA500;
}
</style>

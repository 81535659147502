<template>
    <div class="banner-overlapse-card flex-column ai-c jc-fe">
        <div class="banner-overlapse-card__container flex-row ai-c jc-c">
            <div class="banner-overlapse-card__image-container flex-row ai-c jc-c">
                <el-image
                    :src="require(`@/assets/images/dashboard-initial-banner.png`)"
                    :fit="'cover'">
                </el-image>
            </div>
        </div>
        <div class="banner-overlapse-card__overlapse-card">
            <DefaultCardSlot cardHeight="auto" :showDropdownMenu="false">
                <slot name="card-content"></slot>
            </DefaultCardSlot>
        </div>
    </div>
</template>
<script>
import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';

export default {
  components: {
    DefaultCardSlot
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

 .banner-overlapse-card {
    border-radius: 20px;
    height: auto;
    width: 100%;
    border-radius: 20px;

    &__container {
      position: relative;
      width: 100%;
      height: 150px;
      overflow: hidden;
      border-radius: 20px;
    }

    &__image-container {
        height: 110%;
        width: 110%;
        border-radius: 20px;
        position: absolute;
        filter: drop-shadow(0px 0px 20px rgba(12, 15, 74, 0.1));

        .el-image {
          width: 100%;
          height: 100%;
        }
    }
    &__overlapse-card {
        padding: 0 2rem;
        width: 100%;
        margin-top: -4rem;
        height: auto;
        box-sizing: border-box;
    }
 }

 @include media-sm-max-width() {
    .banner-overlapse-card {
        &__overlapse-card {
            padding: 0 .5rem;
        }
    }
 }
</style>

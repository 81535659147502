// import { ArrowRightBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { HEADER_JOBS } from '@/core/constants/header';
import { USERS_STORE } from '@/store/modules/users';
const __default__ = defineComponent({
    props: [
        'hasSetupInitialAccountSetup',
        'statusVerified',
        'boxFor',
        'isGrid',
        'thumbnail',
        'buttonText',
        'dataCount',
        'label',
        'ctaText',
        'hasSetupProfile',
        'event',
        'isNotDefaultWorkstation',
        'alias',
        'color',
        'disableBtn',
        'noRestriction'
    ],
    components: {
    // ArrowRightBold
    },
    computed: {
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
        ...mapGetters(USERS_STORE, ['isCohort']),
        TpNotVerified() {
            return !this.statusVerified && this.isTradesperson && !this.isCohort && !this.noRestriction;
        },
        PoNotSetupInitialAccount() {
            return !this.hasSetupInitialAccountSetup && this.isProjectOwner && !this.isCohort;
        },
        showActionLabel() {
            return !this.isNotDefaultWorkstation && this.label && !this.dataCount;
        }
    },
    emits: ['handle-function-call'],
    data() {
        return {
            HEADER_JOBS
        };
    },
    methods: {
        handleFunctionCall(event) {
            this.$emit('handle-function-call', event);
        },
    },
});
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
    _useCssVars(_ctx => ({
        "2f07f0f8": (_ctx.$props.color)
    }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__
    ? (props, ctx) => { __injectCSSVars__(); return __setup__(props, ctx); }
    : __injectCSSVars__;
export default __default__;

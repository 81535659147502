<template>
    <BannerWithOverlapseCardSlot>
        <template #card-content>
          <PropertyDetailsContent :property="property"/>

          <!-- MODAL -->
          <EditPropertyDetailsModal :property="property" :show="getOpenEditPropertyDetailsModal" @on-confirm="setOpenEditPropertyDetailsModal(false)" @on-cancel="setOpenEditPropertyDetailsModal(false)"/>
        </template>
    </BannerWithOverlapseCardSlot>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import PropertyDetailsContent from '@/core/components/common/widgets/contents/PropertyDetailsContent.vue';
import BannerWithOverlapseCardSlot from '@/core/components/slots/BannerWithOverlapseCardSlot.vue';
import EditPropertyDetailsModal from '@/modules/property-details-children/components/modal/EditPropertyDetailsModal.vue';
import { PROPERTIES_STORE } from '@/store/modules/properties';

export default defineComponent({
  props: ['property'],
  components: {
    BannerWithOverlapseCardSlot,
    PropertyDetailsContent,
    EditPropertyDetailsModal
  },
  computed: {
    ...mapGetters(PROPERTIES_STORE, ['getOpenEditPropertyDetailsModal']),
  },
  methods: {
    ...mapActions(PROPERTIES_STORE, ['setOpenEditPropertyDetailsModal']),
  }
});
</script>
<style lang="scss" scoped>
</style>

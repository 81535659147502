import { ArrowRightBold, CloseBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import Button from '@/core/components/ui/Button.vue';
import { PROPERTIES_STORE } from '@/store/modules/properties';
export default defineComponent({
    name: 'energy-efficiency-rating-modal',
    emits: ['on-confirm', 'on-cancel'],
    props: ['loading', 'property'],
    components: {
        BaseModal,
        CloseBold,
        ArrowRightBold,
        Button
    },
    data() {
        return {
            showPotentialInMobile: false,
            ratingData: [
                {
                    score: '92+',
                    rating: 'A',
                    class: 'a'
                },
                {
                    score: '81-91',
                    rating: 'B',
                    class: 'b'
                },
                {
                    score: '69-80',
                    rating: 'C',
                    class: 'c'
                },
                {
                    score: '55-68',
                    rating: 'D',
                    class: 'd'
                },
                {
                    score: '39-54',
                    rating: 'E',
                    class: 'e'
                },
                {
                    score: '21-38',
                    rating: 'F',
                    class: 'f',
                    label: 'Can’t legally be rented out!'
                },
                {
                    score: '1-20',
                    rating: 'G',
                    class: 'g',
                    label: 'Can’t legally be rented out!'
                },
            ]
        };
    },
    computed: {
        currentEfficiency() {
            if (this.property) {
                return this.property.epcData['current-energy-efficiency'];
            }
            return null;
        },
        potentialEfficiency() {
            if (this.property) {
                return this.property.epcData['potential-energy-efficiency'];
            }
            return null;
        },
        currentRating() {
            if (this.property) {
                return this.property.epcData['current-energy-rating'];
            }
            return null;
        },
        potentialRating() {
            if (this.property) {
                return this.property.epcData['potential-energy-rating'];
            }
            return null;
        }
    },
    methods: {
        ...mapActions(PROPERTIES_STORE, ['setOpenEnergyEfficiencyModal', 'setOpenEnergyEfficiencyRatingModalfalse']),
        onConfirm() {
            this.$emit('on-confirm');
        },
        onCancel() {
            this.$emit('on-cancel');
        },
        toggleMenu() {
            this.showPotentialInMobile = !this.showPotentialInMobile;
        },
        handleClick() {
            this.setOpenEnergyEfficiencyRatingModalfalse(false);
            this.setOpenEnergyEfficiencyModal(true);
        }
    }
});

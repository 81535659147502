<template>
    <el-card>
      <div class="card-content flex-column">
        <el-collapse class="item" accordion>
            <el-collapse-item name="1">
                <template #title>
                    <div class="item__phase-header flex-row jc-sb ai-c">
                        <div class="col flex-row ai-c">
                            <el-checkbox v-model="isCheck" size="large" />
                            <div class="phase-label flex-row ai-c jc-c">
                                <p class="default-text">Step {{index + 1}}</p>
                            </div>
                            <p class="phase-title default-text">Cavity wall insulation</p>
                        </div>
                        <div class="col flex-row ai-c">
                            <el-icon :size="12" color="#F8D648"><Top/></el-icon>
                            <p class="phase-summary-data default-text flex-row ai-fe">60</p>
                            <el-divider direction="vertical"/>
                            <p class="phase-summary-data default-text flex-row ai-fe">D</p>
                        </div>
                    </div>
                </template>
                <div class="item__phase-task-container flex-column">
                    <div class="detail-container flex-row jc-sb">
                       <p class="default-text">Estimated installation cost</p>
                       <div class="col flex-row ai-c">
                          <p class="phase-summary-data default-text flex-row ai-fe">£500 - £1,000</p>
                       </div>
                    </div>
                    <div class="detail-container flex-row jc-sb">
                       <p class="default-text">Average annual saving</p>
                       <div class="col flex-row ai-c">
                          <p class="phase-summary-data default-text flex-row ai-fe">£248</p>
                       </div>
                    </div>
                    <div class="detail-container flex-row jc-sb">
                       <p class="default-text">Potential new energy rating</p>
                       <div class="col flex-row ai-c">
                            <el-icon :size="12" color="#F8D648"><Top/></el-icon>
                            <p class="phase-summary-data default-text flex-row ai-fe">60</p>
                            <el-divider direction="vertical"/>
                            <p class="phase-summary-data default-text flex-row ai-fe">D</p>
                        </div>
                    </div>
                    <div class="detail-container flex-row jc-sb">
                       <p class="note default-text">NOTE: Turn <span class="default-text">Step 1</span> into a <span class="default-text">New project</span> by ticking the box and receive accurate estimates from professional trade contractors.</p>
                    </div>
                </div>
            </el-collapse-item>
        </el-collapse>
      </div>
    </el-card>
</template>
<script>
import { Top } from '@element-plus/icons';

export default {
  components: {
    Top
  },
  props: ['index'],
  data() {
    return {
      isCheck: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.el-card {
    box-sizing: border-box;
    border-radius: 6px;
    border:none;

    :deep(.el-card__body) {
        box-sizing: border-box;
        padding:0;
    }

    .card-content {
      .item {
        border-color: #F1F6FB;
      }
    }
}

.el-card.is-always-shadow {
    box-shadow: 0px 0px 10px 0px rgba(42, 42, 42, 0.02);
}
.default-text {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.25px;
    text-align: left;
    margin: 0;
    color: #0C0F4A;
}
.flex-row {
   display: flex;
   gap: 1rem;
}
.flex-column {
    @extend .flex-row;
    flex-direction: column;
}
.ai-c {
    align-items: center;
}
.ai-fe {
    align-items: flex-end;
}
.jc-c {
    justify-content: center;
}
.jc-sb {
    justify-content: space-between;
}
.jc-fe {
    justify-content: flex-end;
}
.card-content {
    position: relative;
}
.item {
    border-color: #F1F6FB;
}
.item__phase-header {
    width: 100%;
    gap: .5rem;
}
.col {
    gap: .5rem;
}
.phase-label {
    max-width: 70px;
    min-width: 70px;
    height: 22px;
    border-radius: 2px;
    background: rgba(74, 146, 229, 0.1);
    box-sizing: border-box;

    p {
        font-size: 9px;
        font-weight: 500;
        text-align: center;
        color: #4A92E5;
    }
}
.phase-title {
    font-size: 13px;
    color: #4A92E5;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60%;
    line-height: 1.2em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.phase-summary-data {
    font-size: 13px;
    text-align: right;
    color: #0C0F4A;
    gap: .3rem;
}

.el-divider {
    margin: 0;
}

.item__phase-task-container {
    // padding: 1.5rem 0 0 0;
    gap: .2em;
}
.detail-container:last-child {
    border-bottom: none;
    padding-bottom: 0;
}
.detail-container {
    border-bottom: 2px solid rgba(12, 15, 74, 0.2);
    padding-bottom: .5em;
}
.note {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;

    span {
        font-size: 12px;
        line-height: 20px;
        color:#FFA500;
    }
}
:deep(.title) {
}
:deep(.el-collapse-item__header) {
    background-color: transparent;
    gap:1rem;
    outline:none;
    border:1px solid transparent;
}
:deep(.el-collapse-item__wrap) {
    padding: 0 1rem 0 1.5rem;
}
:deep(.el-collapse-item__content) {
    @extend .default-text;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(12, 15, 74, 0.8);
}
</style>

<template>
    <div class="dashboard-project-detail-box flex-column">
        <div class="dashboard-project-detail-box__row grid">
           <DetailsBoxItemComponent bgColor="#4F55F0" :isDarkBg="true" :icon="inProgress" :data="inProgressTotalCount" label="In progress"/>
           <DetailsBoxItemComponent bgColor="#0C0F4A" :isDarkBg="true" :icon="estimates" :data="estimatesReceivedTotalCount" label="Estimates received"/>
           <DetailsBoxItemComponent bgColor="#FFF" :data="notStartedTotalCount" :icon="notStarted" label="Not started"/>
        </div>
        <div class="dashboard-project-detail-box__row flex-row ai-c jc-fe cursor-pointer">
            <p class="dashboard-project-detail-box__cta-text default-text" @click="routeToPropertyList">{{ widgetLabel }}</p>
            <el-icon :size="15" color="#FFA500"><ArrowRightBold/></el-icon>
        </div>
    </div>
</template>
<script>
import { ArrowRightBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import DetailsBoxItemComponent from '@/modules/dashboard/components/new-components/DetailsBoxItemComponent.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    ArrowRightBold,
    DetailsBoxItemComponent
  },
  props: ['notStartedTotalCount', 'inProgressTotalCount', 'estimatesReceivedTotalCount'],
  data() {
    return {
      /* eslint-disable global-require */
      estimates: `${require('@/assets/icons/white-filled-estimates-icon.svg')}`,
      inProgress: `${require('@/assets/icons/white-filled-in-progress-icon.svg')}`,
      notStarted: `${require('@/assets/icons/yellow-filled-not-started-icon.svg')}`
      /* eslint-disable global-require */
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['isWsAdmin', 'isWsEditor', 'isWsViewer']),

    widgetLabel() {
      const { isWsViewer } = this;
      let label = 'Manage projects';

      if (isWsViewer) {
        label = 'View projects';
      }

      return label;
    }
  },

  methods: {
    routeToPropertyList() {
      this.$router.push('projects');
    },
  }
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

.dashboard-project-detail-box {
    &__row {
        gap: .5rem;
    }
    &__cta-text {
        font-size: 14px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.25px;
        text-align: right;
        color: #FFA500;
    }
}
.grid {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.3rem;
}

@include media-sm-max-width() {
    .grid {
        gap: 1rem;
    }
}
</style>

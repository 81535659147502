import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import WidgetEmptyState from '@/core/components/common/widgets/WidgetEmptyState.vue';
import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
// import { PAGE_LIMIT } from '@/core/constants';
import events from '@/core/constants/event';
import { HEADER_PROJECTS } from '@/core/constants/header';
import { ProjectJobStatusEnum } from '@/core/packages/shared-library';
import ProjectsDetailsBoxComponent from '@/modules/dashboard/components/new-components/ProjectsDetailsBoxComponent.vue';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    components: {
        DefaultCardSlot,
        WidgetEmptyState,
        ProjectsDetailsBoxComponent,
    },
    data() {
        return {
            isLoading: false,
            notStartedTotalCount: 0,
            inProgressTotalCount: 0,
            estimatesReceivedTotalCount: 0,
            projectListMetaData: {
                limit: 100,
                page: 1,
                totalCount: 0,
                totalPages: 0
            },
            myProjects: [],
            currentFilters: {
                limit: 100,
                propertyIds: []
            },
            events,
            HEADER_PROJECTS,
            /* eslint-disable global-require */
            projectsPopOverData: [
                {
                    icon: `${require('@/assets/icons/projects-filled-icon.svg')}`,
                    name: 'View all projects',
                    event: events.ROUTE_TO_PROJECTS,
                },
                {
                    icon: `${require('@/assets/icons/add-project-filled-icon.svg')}`,
                    name: 'Start new project',
                    event: events.ROUTE_TO_SELECT_PROPERTY,
                },
            ],
            jobsThumbnail: `${require('@/assets/images/projects-empty-image-png.png')}`,
        };
    },
    computed: {
        ...mapGetters(['isProjectOwner']),
        ...mapGetters(USERS_STORE, ['user', 'wsRole', 'isWsAdmin', 'isWsEditor', 'isWsViewer']),
        ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
        hasSetupInitialAccountSetup() {
            return this.user?.hasAccountSetup;
        },
        widgetLabel() {
            const { isWsEditor, isWsViewer } = this;
            let label = 'Start new project';
            if (isWsEditor) {
                label = 'Manage Projects';
            }
            if (isWsViewer) {
                label = 'View Projects';
            }
            return label;
        }
    },
    watch: {
        activeUserWorkstation: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value?.alias) {
                    this.initialize();
                }
            },
        },
    },
    created() {
    },
    methods: {
        ...mapActions(['setIsLoading']),
        ...mapActions(PROJECTS_STORE, ['getProjectPageList', 'getProjectJobStatusWithCountState', 'getProjectMyJobList', 'getProjectByRefId']),
        routeToStartNewProject() {
            if (this.wsRole && !this.isWsAdmin) {
                this.$router.push('projects');
            }
            else {
                this.$router.push({ name: 'select-property' });
            }
        },
        handleFunctionCall(event) {
            if (event === this.events.ROUTE_TO_SELECT_PROPERTY) {
                this.routeToStartNewProject();
            }
        },
        /* eslint-disable no-param-reassign */
        addProjectStatus() {
            const cmp = this;
            /* eslint-disable-next-line no-plusplus */
            cmp.notStartedTotalCount = cmp.myProjects.reduce((sum, obj) => (obj.projectJobStatus.id === ProjectJobStatusEnum.AWAITING_QUOTES ? ++sum : sum), 0);
            /* eslint-disable-next-line no-plusplus */
            cmp.inProgressTotalCount = cmp.myProjects.reduce((sum, obj) => (obj.projectJobStatus.id === ProjectJobStatusEnum.IN_PROGRESS ? ++sum : sum), 0);
            /* eslint-disable-next-line no-plusplus */
            cmp.estimatesReceivedTotalCount = cmp.myProjects.reduce((sum, obj) => (obj.projectJobStatus.id === ProjectJobStatusEnum.QUOTES_RECEIVED ? ++sum : sum), 0);
        },
        /* eslint-disable no-param-reassign */
        getMyProjects(filters) {
            this.isLoading = true;
            this.myProjects = [];
            this.getProjectPageList(filters)
                .then((response) => {
                this.myProjects = response.data;
                if (response.data.length > 0) {
                    this.addProjectStatus();
                }
                const { limit, page, totalCount, totalPages } = response;
                this.projectListMetaData = {
                    limit,
                    page,
                    totalCount,
                    totalPages
                };
            })
                .catch(() => {
                this.isLoading = false;
            })
                .finally(() => {
                this.isLoading = false;
            });
        },
        initialize() {
            const { currentFilters } = this;
            const { id } = this.$route.params;
            if (id) {
                currentFilters.propertyIds.push(id);
            }
            this.getMyProjects(currentFilters);
        }
    },
});

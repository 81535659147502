<template>
<BaseModal
  :show="show"
  :showCancelBtn="false"
  :showSubmitBtn="false"
  :showDefaultCloseBtn="false"
  @cancel="cancel"
  :hasActions="false"
>
  <div class="content flex-column jc-sb">
    <div class="modal-header flex-row jc-sb ai-c">
      <h2 class="modal-header__title default-text">Energy efficiency</h2>
      <div class="modal-header__icon">
       <inline-svg @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
      </div>
    </div>
    <div class="efficiency flex-column jc-sb">
        <div class="content-container flex-column">
           <div class="header-description-container flex-column">
                <p class="header-description default-text">
                    We suggest to follow each step in order so you can save the most energy.
                </p>
                <p class="header-description default-text">
                    For example, it’s more energy efficient to insulate your home before you install a new heating or cooling system. A well insulated home provides resistance to heat flow so you do not have to run your heating or cooling system as often. This provides year-round comfort, cutting cooling and heating bills, and reducing greenhouse gas emissions.
                </p>
            </div>
            <div class="status-container flex-column">
                <p class="header-status default-text flex-row jc-fe">Current energy rating: 49 | E</p>
                <el-scrollbar>
                    <div class="step-content flex-column">
                        <StepDropdownItemComponent v-for="(item, index) in stepsCount" :key="index" :index="index"/>
                    </div>
                </el-scrollbar>
            </div>
        </div>
        <div class="footer-cta-container flex-column">
          <p class="footer-cta default-text">Check which energy grants is your property eligible for?</p>
        </div>
    </div>
    <div class="actions">
      <el-button @click="confirm" class="button" :class="hasSelected && 'active'">GET ESTIMATES</el-button>
    </div>
  </div>
</BaseModal>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';

import BaseModal from '@/core/components/ui/BaseModal.vue';
import StepDropdownItemComponent from '@/modules/property-details-children/components/StepDropdownItemComponent.vue';
import { PROPERTIES_STORE } from '@/store/modules/properties';

export default defineComponent({
  name: 'energy-efficiency-modal',
  emits: ['close'],
  components: {
    BaseModal,
    StepDropdownItemComponent
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      stepsCount: 5,
    };
  },
  //   watch: {
  //     'utilityForm.provider': {
  //       handler() {
  //         (this as any).fieldChecker();
  //       }
  //     },
  //   },
  computed: {
    ...mapGetters(PROPERTIES_STORE, ['getOpenEnergyEfficiencyModal']),
  },
  methods: {
    ...mapActions(PROPERTIES_STORE, ['setOpenEnergyEfficiencyModal']),

    cancel() {
      this.$emit('close');
    },

    confirm() {
      this.$emit('close');
    },
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.default-text {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
    margin: 0;
    color: #0C0F4A;
}
.flex-row {
   display: flex;
   gap: 1rem;
}
.flex-column {
    @extend .flex-row;
    flex-direction: column;
}
.ai-c {
    align-items: center;
}
.jc-c {
    justify-content: center;
}
.jc-sb {
    justify-content: space-between;
}
.jc-fe {
    justify-content: flex-end;
}
.content {
  position: relative;
  font-family: "Mullish", Arial;
  height: 70vh;
  gap: 2rem;
  max-width: 600px;

  .modal-header {
    &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.15px;
    }
    &__icon {
        cursor: pointer;
    }
  }
}
.efficiency {
  height: 100%;
}
.content-container {
    height: 100%;
}
.header-description {
    font-size: 12px;
    line-height: 20px;
}
.status-container {
    gap: .3em;
    height: calc(100% - 16.5rem);
}
.header-status {
    width: 100%;
}
.step-content {
    gap:1.5rem;
    padding: .5rem .4rem 1rem .4rem;
}
.footer-cta-container {
    position: absolute;
    bottom: 3rem;
}
.footer-cta {
    cursor: pointer;
    line-height: 20px;
    text-decoration-line: underline;
    color: #545CFF;
}
.actions {
    position: absolute;
    bottom: 0;
}
.button {
    height: 40px;
    padding: 0 2rem;
    box-sizing: border-box;
    background-color: rgba(12, 15, 74, 0.05);
    border: none;
    border-radius: 7px;
    @extend .default-text;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: rgba(12, 15, 74, 0.5);
}
.active {
    background-color: #FFA500;
    color: #fff;
}

@include media-xs-max-width() {
  .status-container {
    height: calc(100% - 24.5rem);
  }
}
</style>

<template>
    <div class="property-details-content flex-column">
        <div class="property-details-content__container flex-row ai-c jc-sb">
            <p class="property-details-content__title default-text">{{property?.address}}</p>
            <div class="property-details-content__wrapper flex-row ai-c hide-in-mobile">
                <!-- icon -->
              <p class="property-details-content__label gray default-text">{{property?.postcode}}</p>
            </div>
        </div>

        <!-- FOR MOBILE VIEW -->
        <div class="property-details-content__container flex-row ai-c jc-sb show-in-mobile">
            <div class="property-details-content__wrapper flex-row ai-c">
                <p class="property-details-content__label gray default-text">Property ID: #{{property?.uprn}}</p>
            </div>
            <div class="property-details-content__wrapper flex-row ai-c">
                <!-- icon -->
              <p class="property-details-content__label gray default-text">{{property?.postcode}}</p>
            </div>
        </div>
        <!-- FOR MOBILE VIEW -->

        <div class="property-details-content__container flex-row ai-c jc-sb">
            <div class="property-details-content__wrapper flex-row ai-c" style="gap: 1rem;">
               <div class="property-details-content__wrapper flex-row ai-c" v-for="(item, index) in houseDetails" :key="index">
                  <inline-svg :src="item.icon"></inline-svg>
                  <p class="property-details-content__label default-text">{{item.name === 'beds' ? property?.beds : item.name === 'baths' ? property?.baths : property?.reception}} {{item.name}}</p>
                </div>
                <div class="property-details-content__wrapper flex-row ai-c cursor-pointer" v-if="canEdit" @click="setOpenEditPropertyDetailsModal(true)">
                  <inline-svg :src="require('@/assets/icons/edit-pen-yellow-filled-icon.svg')" v-if="!disableEdit"></inline-svg>
                  <p class="property-details-content__label yellow default-text hide-in-mobile" v-if="!disableEdit">Edit</p>
                </div>
            </div>
            <div class="property-details-content__wrapper flex-row ai-c hide-in-mobile">
                <p class="property-details-content__label gray default-text">Property ID: #{{property?.uprn}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import { PROPERTIES_STORE } from '@/store/modules/properties';
import { USERS_STORE } from '@/store/modules/users';

export default {
  props: ['property', 'disableEdit'],
  data() {
    return {
      houseDetails: [
        /* eslint-disable global-require */
        {
          icon: `${require('@/assets/icons/bed-icon.svg')}`, name: 'beds'
        },
        {
          icon: `${require('@/assets/icons/bath-icon.svg')}`, name: 'baths'
        },
        {
          icon: `${require('@/assets/icons/reception-icon.svg')}`, name: 'reception'
        },
        /* eslint-disable global-require */
      ],
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['wsRole', 'isWsViewer']),

    canEdit() {
      const { wsRole, isWsViewer } = this;
      let show = true;

      if (wsRole && isWsViewer) {
        show = !show;
      }

      return show;
    },
  },

  methods: {
    ...mapActions(PROPERTIES_STORE, ['setOpenEditPropertyDetailsModal']),
  }
};
</script>
<style lang="scss" scoped>
@use "../../../../../assets/scss/mixins/media-query" as *;
@use "../../../../../assets/scss/variables/layout" as *;

.property-details-content {
    gap: .5rem;
    &__wrapper {
        gap: .5rem;
        white-space: nowrap;
    }
    &__title {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0px;
    }
    &__label {
        font-size: 14px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.25px;
    }
}
.gray {
    color: rgba(12, 15, 74, 0.5);
}
.yellow {
    color: #FFA500;
}

@include media-sm-max-width() {
    .property-details-content  {
      &__title {
            font-size: 17px;
        }
        &__label {
            font-size: 12px;
            font-weight: 400;
        }
    }
}

@include media-xs-max-width() {
    .property-details-content  {
      &__title {
            font-size: 14px;
        }
        &__label {
            font-size: 10px;
            font-weight: 400;
        }
    }
}
</style>

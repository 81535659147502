<template>
    <div class="property-home-page grid">
      <div class="property-home-page__container flex-column" v-if="property">
        <PropertyBannerWidget :property="property"/>
        <div class="property-home-page__default-grid grid">
          <PropertyEPCDetailsWidget :property="property" height="300px"/>
          <ProjectWidgetComponent/>
          <LogWidget
            title="Property log"
            :data="filteredRecommendation"
            :loadingData="loadingRecommendation"
            :isClickable="true"
            @handle-click="routeToProjectOverview($event)"/>
          <KYPWidget cardHeight="397px" :property="property"/>
        </div>
      </div>
      <div class="property-home-page__container flex-column" v-if="property">
        <EventWidget cardHeight="987px"/>
      </div>
      <Loader v-show="!property && isLoading"/>
  </div>
</template>
<script>
import axios from 'axios';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { VUE_APP_EPC_API, VUE_APP_EPC_RECOMMENDATIONS } from '@/config';
import EventWidget from '@/core/components/common/widgets/EventWidget.vue';
import KYPWidget from '@/core/components/common/widgets/KYPWidget.vue';
import LogWidget from '@/core/components/common/widgets/LogWidget.vue';
import ProjectWidgetComponent from '@/core/components/common/widgets/ProjectWidgetComponent.vue';
import PropertyBannerWidget from '@/core/components/common/widgets/PropertyBannerWidget.vue';
import PropertyEPCDetailsWidget from '@/core/components/common/widgets/PropertyEPCDetailsWidget.vue';
import Loader from '@/core/components/ui/Loader.vue';
import { PAGE_LIMIT } from '@/core/constants';
// import { GENERIC_ERROR_MESSAGE } from '@/core/constants/messages';
import RawCurrentDateTime from '@/filters/date.filter';
import { PROJECT_POST_OVERVIEW_ROUTE } from '@/modules/project-post-overview/routes';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { PROJECT_POST_STORE } from '@/store/modules/projects/submodules/project-post';
import { PROPERTIES_STORE } from '@/store/modules/properties';
import { PROPERTY_STORE } from '@/store/modules/property-details';

export default defineComponent({
  components: {
    ProjectWidgetComponent,
    PropertyEPCDetailsWidget,
    KYPWidget,
    PropertyBannerWidget,
    EventWidget,
    LogWidget,
    Loader
  },
  data() {
    return {
      property: null,
      recommendations: null,
      filteredRecommendation: [],
      loadingRecommendation: false,
      projects: null,
      currentFilters: {
        page: 1,
        limit: PAGE_LIMIT,
        projectJobStatusIds: [],
        propertyIds: []
      },
      recommendationStartingMessage: '<span style="color: #FFA500">Recommendations: Start</span>',
      recommendationClosingMessage: 'project. <span style="color: #4F55F0;">Click here</span>',

      calendarEvents: [],

      dashboardSchedulesMockData: [
        {
          id: 4,
          dateCreated: '2023-08-29T13:43:12.125Z',
          dateUpdated: '2023-08-29T13:47:25.000Z',
          title: null,
          type: 'project',
          preference: 'IN_PERSON',
          note: "Let's meet and discuss this project",
          recipients: [
            {
              email: 'sheryl.henry@po1.com',
              status: 'CONFIRMED'
            }
          ],
          schedule: [
            {
              date: '2023-09-01',
              timeslots: [
                {
                  value: '',
                  timeRange: '07:00 - 08:00'
                },
              ]
            }
          ],
          meta: {
            id: 'MRIzrl1NKXji6p92fp3hm'
          },
          user: {
            id: 961,
            dateCreated: '2022-12-25T21:44:02.989Z',
            dateUpdated: '2023-07-06T04:01:54.000Z',
            firstName: 'Freda',
            lastName: 'Kelley',
            email: 'freda.kelley@tp1.com',
            birthDate: '2000-02-02',
            postcode: 'SS12NS',
            active: true,
            hasChosenRole: true,
            hasAccountSetup: true,
            hasBusinessType: true,
            accountSetupCache: null,
            verifiedEmail: true,
            userVerificationStatus: '2',
            contactNo: '07805158752',
            address: 'Elm St.',
            tcOptIn: false,
            marketingOptIn: true,
            latitude: '51.538938',
            longitude: '0.720477',
            referralCode: 487501,
            referredCode: null,
            tpProfileSubscription: '{"customerId":"cus_NO3kY81vgJT0QV","subscriptionId":"sub_1MidSBHMbjzNI6kGpr1tyOnG","metadata":{"productName":"tradesperson-profile-961","userId":"961"},"created":1678106848}',
            userPublicProfile: null
          },
          userWorkstation: {
            id: 670,
            dateCreated: '2022-12-26T15:05:16.000Z',
            dateUpdated: '2023-08-15T16:39:30.000Z',
            name: 'Default',
            status: 'active',
            subscription: null,
            maxMemberLimit: 0,
            seatsUsed: 0,
            verificationStatus: '2',
            submittedInformationAt: '2023-08-15T03:48:40.000Z'
          }
        },
        {
          id: 4,
          dateCreated: '2023-08-29T13:43:12.125Z',
          dateUpdated: '2023-08-29T13:47:25.000Z',
          title: null,
          type: 'project',
          preference: 'VIDEO_CALL',
          note: "Let's meet and discuss this project",
          recipients: [
            {
              email: 'sheryl.henry@po1.com',
              status: 'CONFIRMED'
            }
          ],
          schedule: [
            {
              date: '2023-09-01',
              timeslots: [
                {
                  value: '',
                  timeRange: '07:15 - 08:20'
                },
              ]
            }
          ],
          meta: {
            id: 'MRIzrl1NKXji6p92fp3hm'
          },
          user: {
            id: 961,
            dateCreated: '2022-12-25T21:44:02.989Z',
            dateUpdated: '2023-07-06T04:01:54.000Z',
            firstName: 'Freda',
            lastName: 'Kelley',
            email: 'freda.kelley@tp1.com',
            birthDate: '2000-02-02',
            postcode: 'SS12NS',
            active: true,
            hasChosenRole: true,
            hasAccountSetup: true,
            hasBusinessType: true,
            accountSetupCache: null,
            verifiedEmail: true,
            userVerificationStatus: '2',
            contactNo: '07805158752',
            address: 'Elm St.',
            tcOptIn: false,
            marketingOptIn: true,
            latitude: '51.538938',
            longitude: '0.720477',
            referralCode: 487501,
            referredCode: null,
            tpProfileSubscription: '{"customerId":"cus_NO3kY81vgJT0QV","subscriptionId":"sub_1MidSBHMbjzNI6kGpr1tyOnG","metadata":{"productName":"tradesperson-profile-961","userId":"961"},"created":1678106848}',
            userPublicProfile: null
          },
          userWorkstation: {
            id: 670,
            dateCreated: '2022-12-26T15:05:16.000Z',
            dateUpdated: '2023-08-15T16:39:30.000Z',
            name: 'Default',
            status: 'active',
            subscription: null,
            maxMemberLimit: 0,
            seatsUsed: 0,
            verificationStatus: '2',
            submittedInformationAt: '2023-08-15T03:48:40.000Z'
          }
        },
        {
          id: 4,
          dateCreated: '2023-08-29T13:43:12.125Z',
          dateUpdated: '2023-08-29T13:47:25.000Z',
          title: null,
          type: 'project',
          preference: 'VIDEO_CALL',
          note: "Let's meet and discuss this project",
          recipients: [
            {
              email: 'sheryl.henry@po1.com',
              status: 'CONFIRMED'
            }
          ],
          schedule: [
            {
              date: '2023-09-01',
              timeslots: [
                {
                  value: '07:00',
                },
              ]
            },
            {
              date: '2023-09-02',
              timeslots: [
                {
                  value: '08:00',
                },
                {
                  value: '09:00',
                  status: 'CONFIRMED'
                },
              ]
            }
          ],
          meta: {
            id: 'MRIzrl1NKXji6p92fp3hm'
          },
          user: {
            id: 961,
            dateCreated: '2022-12-25T21:44:02.989Z',
            dateUpdated: '2023-07-06T04:01:54.000Z',
            firstName: 'Freda',
            lastName: 'Kelley',
            email: 'freda.kelley@tp1.com',
            birthDate: '2000-02-02',
            postcode: 'SS12NS',
            active: true,
            hasChosenRole: true,
            hasAccountSetup: true,
            hasBusinessType: true,
            accountSetupCache: null,
            verifiedEmail: true,
            userVerificationStatus: '2',
            contactNo: '07805158752',
            address: 'Elm St.',
            tcOptIn: false,
            marketingOptIn: true,
            latitude: '51.538938',
            longitude: '0.720477',
            referralCode: 487501,
            referredCode: null,
            tpProfileSubscription: '{"customerId":"cus_NO3kY81vgJT0QV","subscriptionId":"sub_1MidSBHMbjzNI6kGpr1tyOnG","metadata":{"productName":"tradesperson-profile-961","userId":"961"},"created":1678106848}',
            userPublicProfile: null
          },
          userWorkstation: {
            id: 670,
            dateCreated: '2022-12-26T15:05:16.000Z',
            dateUpdated: '2023-08-15T16:39:30.000Z',
            name: 'Default',
            status: 'active',
            subscription: null,
            maxMemberLimit: 0,
            seatsUsed: 0,
            verificationStatus: '2',
            submittedInformationAt: '2023-08-15T03:48:40.000Z'
          }
        }
      ]
    };
  },
  async created() {
    await this.initialize();
  },
  methods: {
    ...mapActions(['setIsLoading']),
    ...mapActions(PROJECTS_STORE, ['getProjectPageList']),
    ...mapActions(PROPERTY_STORE, ['getProperty']),
    ...mapActions(PROJECT_POST_STORE, ['setEpcRecommendationProjectDetails']),

    async initialize() {
      this.loadingRecommendation = true;
      const { id } = this.$route.params;
      await this.getProperty(id).then((raw) => {
        const result = { ...raw, addressData: JSON.parse(raw.addressData), epcData: JSON.parse(raw.epcData) };
        const lmkKey = result?.epcData['lmk-key'] || null;

        if (!raw.subscription) {
          this.routeToPropertyListing();
        }

        this.property = result;

        if (lmkKey) {
          this.getEpcRecommendationData(this.property?.epcData['lmk-key']);
        } else {
          this.setRecommendationloaderFalse();
        }
      })
        .finally(() => {
          this.setIsLoading(false);
        });
    },
    routeToPropertyListing() {
      this.$router.push({ name: 'property-listing' });
    },
    async getEpcRecommendationData(lmkKey) {
      try {
        const response = await axios.get(`${VUE_APP_EPC_RECOMMENDATIONS}/${lmkKey}`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Basic ${VUE_APP_EPC_API}`,
          }
        });

        if (response?.data) {
          this.recommendations = response.data?.rows || [];
          this.filterRecommendationProjects();
        }
      } catch (e) {
        // this.$notify.error({
        //   title: 'Error in fetching epc recommendations',
        //   message: 'No recommendation data'
        // });
        this.setRecommendationloaderFalse();
      }
    },
    async loadProjects(filters) {
      await this.getProjectPageList(filters).then((response) => {
        const { data } = response;
        this.projects = data;
      })
        .finally(() => {
          this.setRecommendationloaderFalse();
        });
    },
    filterRecommendationProjects() {
      const { currentFilters } = this;
      const { id } = this.$route.params;

      currentFilters.propertyIds.push(id);

      this.loadProjects(currentFilters)
        .then(() => {
          if (this.projects.length && this.recommendations.length) {
            const projectsWithRecommendationData = this.projects.filter((project) => project.recommendationData !== null);
            this.filterData(projectsWithRecommendationData);
          } else {
            this.filteredRecommendation = this.recommendations.map((item) => {
              item.content = `${this.recommendationStartingMessage} ${item['improvement-id-text']} ${this.recommendationClosingMessage}`;
              item.dateCreated = RawCurrentDateTime.rawCurrentDateTime();
              return item;
            });
          }
        })
        .catch(() => {
          this.setRecommendationloaderFalse();
        })
        .finally(() => {
          this.setRecommendationloaderFalse();
        });
    },
    filterData(projectsWithRecommendationData) {
      if (projectsWithRecommendationData.length) {
        const improvementIds = [];

        for (let i = 0; i < projectsWithRecommendationData.length; i++) {
          const recoData = JSON.parse(projectsWithRecommendationData[i].recommendationData);
          const parseRecoData = JSON.parse(recoData);
          improvementIds.push(parseRecoData['improvement-id']);
        }
        this.filteredRecommendation = this.recommendations.filter((value) => {
          return !improvementIds.includes(value['improvement-id']);
        }).map((item) => {
          const improvementIdText = item['improvement-id-text'];
          item.content = `${this.recommendationStartingMessage} ${improvementIdText} ${this.recommendationClosingMessage}`;
          item.dateCreated = RawCurrentDateTime.rawCurrentDateTime();
          return item;
        });
      } else {
        this.filteredRecommendation = this.recommendations.map((item) => {
          item.content = `${this.recommendationStartingMessage} ${item['improvement-id-text']} ${this.recommendationClosingMessage}`;
          item.dateCreated = RawCurrentDateTime.rawCurrentDateTime();
          return item;
        });
      }
    },
    routeToProjectOverview(data) {
      const { id } = this.$route.params;

      this.setEpcRecommendationProjectDetails(data);

      this.$router.push({ name: PROJECT_POST_OVERVIEW_ROUTE, params: { propertyId: id } });
    },
    setRecommendationloaderFalse() {
      setTimeout(() => {
        this.loadingRecommendation = false;
      }, 100);
    }
  },
  computed: {
    ...mapGetters(['isLoading']),

    ...mapGetters(PROPERTIES_STORE, [
      'getOpenConnectSmartMeterModal',
      'getOpenConfirmationModal',
      'getOpenUtilityModal',
      'getOpenEnergyEfficiencyModal',
      'getOpenEditPropertyDetailsModal',
      'getOpenEnergyEfficiencyRatingModalfalse',
      'getOpenFinanceModal'
    ]),

    getPropertyFullName() {
      if (this.property) {
        const filtered = [
          this.property.address,
          this.property.epcData?.posttown?.toLowerCase(),
          this.property.epcData?.county
        ].filter((x) => x);
        return filtered.join(', ');
      }
      return '-';
    },
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.property-home-page {
  max-width: 1450px;
  padding: .9rem .5rem 1rem .5rem;
  // padding-left: 1rem;
  gap: 2rem;
  width: 100%;
  grid-template-columns: 2fr 1fr;
  box-sizing: border-box;
  // position: relative;

  &__default-grid {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 2rem;
  }
  &__container {
    gap: 2rem;
  }
}

@media screen and (max-width: 1300px) {
  .property-home-page {
    max-width: 1100px;
    grid-template-columns: 1fr;
  }
}

@include media-md-max-width() {
  .property-home-page  {
    padding-bottom: 3rem;
  }
}

@include media-sm-max-width() {
  .property-home-page {
    padding: 0rem .5rem 1rem .5rem;
  }
}
</style>

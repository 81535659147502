<template>
  <BaseModal :hasActions="false" :staticWidth="true">
    <div class="edit-property-details flex-column jc-sb">
      <div class="edit-property-details__header flex-row ai-c jc-sb">
        <p class="edit-property-details__header-title default-text">Property details</p>
        <el-icon :size="16" color="#0C0F4A" class="cursor-pointer" @click="onCancel"><CloseBold/></el-icon>
      </div>
      <div class="edit-property-details__content flex-column">
        <el-form
          class="edit-property-details__form flex-column"
          ref="editPropertyDetailsForm"
          :model="propertyDetailsForm"
          :rules="rules"
          size="small"
          label-position="top"
          status-icon
          :hide-required-asterisk="true"
        >
          <el-form-item
            class="edit-property-details__item"
            label="How any bedrooms does your property have?"
            prop="bedroom"
          >
            <el-select v-model="propertyDetailsForm.beds" clearable placeholder="E.g. 2" class="with-padding">
              <el-option
                v-for="item in numberOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
              <template #prefix>
                <inline-svg :src="require('@/assets/icons/bed-icon.svg')"></inline-svg>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item
            class="edit-property-details__item"
            label="How any bathrooms does your property have?"
            prop="bathroom"
          >
            <el-select v-model="propertyDetailsForm.baths" clearable placeholder="E.g. 1" class="with-padding">
              <el-option
                v-for="item in numberOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
              <template #prefix>
                <inline-svg :src="require('@/assets/icons/bath-icon.svg')"></inline-svg>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item
            class="edit-property-details__item"
            label="How any reception rooms does your property have?"
            prop="reception"
          >
            <el-select v-model="propertyDetailsForm.reception" clearable placeholder="E.g. 1" class="with-padding">
              <el-option
                v-for="item in numberOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
              <template #prefix>
                <inline-svg :src="require('@/assets/icons/reception-icon.svg')"></inline-svg>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item
            class="edit-property-details__item"
            label="Property type"
            prop="propertyType"
          >
            <el-select v-model="propertyDetailsForm.propertyType" clearable placeholder="Select Property type">
              <el-option
                v-for="item in propertyTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            class="edit-property-details__item"
            label="Property size (sqm)"
            prop="propertySize"
          >
            <el-input
              v-model="propertyDetailsForm.propertySize"
              type="number"
              placeholder="E.g. 99"
            >
              <!-- <template #append>
                sqm
              </template> -->
            </el-input>
          </el-form-item>
          <div class="flex-column" v-if="isOutsideUk">
            <el-form-item
              class="edit-property-details__item"
              label="Carbon footprint"
              prop="carbonFootprint"
              >
              <el-input
                v-model="propertyDetailsForm.carbonFootprint"
                type="text"
                placeholder="E.g. 5.8 t of CO2"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              class="edit-property-details__item"
              label="Energy rating"
              prop="energyRating"
              >
              <el-input
                v-model="propertyDetailsForm.energyRating"
                type="text"
                placeholder="E.g. 58"
              >
              </el-input>
            </el-form-item>
          </div>
          <el-form-item
            class="edit-property-details__item"
            label="Tenure"
            prop="tenure"
          >
            <el-select v-model="propertyDetailsForm.tenure" clearable placeholder="E.g. Freehold/ Owner-occupied">
              <el-option
                v-for="item in tenureOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <div class="edit-property-details__lease-input-container flex-row ai-c jc-sb" v-if="propertyDetailsForm.tenure && propertyDetailsForm.tenure === '(Leasehold / Rental (private)'">
          <p class="edit-property-details__label default-text">How many years of lease do you have left? (years)</p>
          <el-input
              v-model="propertyDetailsForm.yearsOfLease"
              type="number"
              placeholder="E.g. 99"
            >
            </el-input>
        </div>
      </div>
      <div class="edit-property-details__footer">
        <el-button type="primary" class="edit-property-details__button flex-row ai-c jc-c default-text"
        :class="{'active': isButtonReady}" @click="onUpdateProperty" round :loading="loading" :disabled="!isButtonReady">save</el-button>
      </div>
    </div>
  </BaseModal>
</template>
<script>
import { CloseBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import { NA_POSTCODE } from '@/core/components/property/constants';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { PROPERTY_STORE } from '@/store/modules/property-details';

export default defineComponent({
  name: 'edit-property-details-modal',
  emits: ['on-confirm', 'on-cancel'],
  props: ['property'],
  components: {
    BaseModal,
    CloseBold
  },
  data() {
    return {
      NA_POSTCODE,
      loading: false,
      isButtonReady: false,
      propertyDetailsForm: {
        beds: '',
        baths: '',
        reception: '',
        propertyType: '',
        propertySize: '',
        tenure: '',
        yearsOfLease: '',
        carbonFootprint: '',
        energyRating: ''
      },
      numberOptions: [
        {
          value: '1',
          label: '1',
        },
        {
          value: '2',
          label: '2',
        },
        {
          value: '3',
          label: '3',
        },
        {
          value: '4',
          label: '4',
        },
        {
          value: '5',
          label: '5',
        },
        {
          value: '6',
          label: '6',
        },
        {
          value: '7',
          label: '7',
        },
        {
          value: '8',
          label: '8',
        },
        {
          value: '9',
          label: '9',
        },
        {
          value: '10',
          label: '10',
        },
      ],
      propertyTypeOptions: [
        {
          value: 'Detached',
          label: 'Detached',
        },
        {
          value: 'Semi-detached',
          label: 'Semi-detached',
        },
        {
          value: 'Terraced',
          label: 'Terraced',
        },
        {
          value: 'Flat/ Maisonette',
          label: 'Flat/ Maisonette',
        },
        {
          value: 'Cottage',
          label: 'Cottage',
        },
        {
          value: 'Bungalow',
          label: 'Bungalow',
        },
      ],
      tenureOptions: [
        {
          value: 'Freehold / Owner-occupied',
          label: 'Freehold / Owner-occupied',
        },
        {
          value: '(Leasehold / Rental (private)',
          label: '(Leasehold / Rental (private)',
        },
        {
          value: 'Social housing / Rental (social)',
          label: 'Social housing / Rental (social)',
        },
      ]
    };
  },
  watch: {
    'propertyDetailsForm.beds': {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.formChecker(newValue, 'beds');
      }
    },
    'propertyDetailsForm.baths': {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.formChecker(newValue, 'baths');
      }
    },
    'propertyDetailsForm.reception': {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.formChecker(newValue, 'reception');
      }
    },
    'propertyDetailsForm.propertyType': {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.formChecker(newValue, 'propertyType');
      }
    },
    'propertyDetailsForm.propertySize': {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.formChecker(newValue, 'propertySize');
      }
    },
    'propertyDetailsForm.tenure': {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.formChecker(newValue, 'tenure');
      }
    },
    'propertyDetailsForm.yearsOfLease': {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.formChecker(newValue, 'yearsOfLease');
      }
    },
  },
  created() {
    this.initialized();
  },
  computed: {
    isOutsideUk() {
      return this.property.postcode === NA_POSTCODE;
    }
  },
  methods: {
    ...mapActions(PROPERTY_STORE, ['updateProperty']),

    onConfirm() {
      this.$emit('on-confirm');
    },
    onCancel() {
      this.$emit('on-cancel');
    },
    initialized() {
      if (this.property) {
        this.propertyDetailsForm.beds = this.property?.beds;
        this.propertyDetailsForm.baths = this.property?.baths;
        this.propertyDetailsForm.reception = this.property?.reception;
        this.propertyDetailsForm.propertyType = this.property?.propertyType;
        this.propertyDetailsForm.propertySize = this.property?.propertySize;
        this.propertyDetailsForm.energyRating = this.property?.energyRating;
        this.propertyDetailsForm.tenure = this.property?.tenure;
        this.propertyDetailsForm.yearsOfLease = this.property?.yearsOfLease;
      }
    },
    async onUpdateProperty() {
      this.loading = true;
      const {
        property, propertyDetailsForm
      } = this;

      // this.values[data.name] = count;
      // this.isEditInputShown = !this.isEditInputShown;

      await this.updateProperty({
        propertyId: property.id,
        formData: {
          beds: propertyDetailsForm.beds,
          baths: propertyDetailsForm.baths,
          reception: propertyDetailsForm.reception,
          propertyType: propertyDetailsForm.propertyType,
          propertySize: propertyDetailsForm.propertySize,
          energyRating: propertyDetailsForm.energyRating,
          tenure: propertyDetailsForm.tenure,
          yearsOfLease: propertyDetailsForm.yearsOfLease,
        }
      }).then(() => {
        this.$notify.success({
          type: 'success',
          message: 'Successfully updated property details'
        });
      })
        .catch((e) => {
          this.$notify.error({
            message: e || 'Error updating property details. Please try again later'
          });
        })
        .finally(() => {
          this.loading = false;
          this.onCancel();
        });
    },
    formChecker(newValue, name) {
      const {
        property
      } = this;
      if (name === 'beds' && newValue !== property?.beds) {
        this.isButtonReady = true;
        return this.isButtonReady;
      }
      if (name === 'baths' && newValue !== property?.baths) {
        this.isButtonReady = true;
        return this.isButtonReady;
      }
      if (name === 'reception' && newValue !== property?.reception) {
        this.isButtonReady = true;
        return this.isButtonReady;
      }
      if (name === 'propertyType' && newValue !== property?.propertyType) {
        this.isButtonReady = true;
        return this.isButtonReady;
      }
      if (name === 'propertySize' && newValue !== property?.propertySize) {
        this.isButtonReady = true;
        return this.isButtonReady;
      }
      if (name === 'tenure' && newValue !== property?.tenure) {
        this.isButtonReady = true;
        return this.isButtonReady;
      }
      if (name === 'yearsOfLease' && newValue !== property?.yearsOfLease) {
        this.isButtonReady = true;
        return this.isButtonReady;
      }
      this.isButtonReady = false;
      return this.isButtonReady;
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

.edit-property-details {
  min-width: 500px;
  &__header-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.25px;
  }
  &__form {
    gap: .7rem;
    .el-form-item {
      margin: 0;
    }
    :deep(.el-form-item__label) {
      font-family: Mulish;
      font-size: 14px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0.25px;
      text-align: left;
      color: #0C0F4A;
    }
    .el-input, .el-select {
      background: rgba(12, 15, 74, 0.05);
      border-radius: 100px;
      border:none;
      outline: none;
      width: 100%;
      height: 42px;
      box-sizing: border-box;

      :deep(.el-input__inner) {
        background: transparent;
        border-radius: 100px;
        border:none;
        height: 42px;
        box-sizing: border-box;
        color: #0C0F4A;
      }
      :deep(.el-input__prefix) {
        left: 15px;
      }
    }
    .el-select.with-padding {
      :deep(.el-input__inner) {
        padding-left: 2.5rem !important;
      }
    }
  }
  &__lease-input-container {
    .el-input, .el-select {
      background: transparent;
      border:none;
      outline: none;
      width: 100px;
      height: 25px;
      box-sizing: border-box;

      :deep(.el-input__inner) {
        background: transparent;
        border:none;
        height: 25px;
        box-sizing: border-box;
        color: #FAA100;
      }
    }
  }
  &__label {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
  }
  &__footer {
    width: 100%;
  }
  &__button {
    width: 100%;
    height: 42px;
    background: rgba(12, 15, 74, 0.1);
    border-radius: 8px;
    border: none;
    color: rgba(12, 15, 74, 0.5);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      background: rgba(12, 15, 74, 0.1);
      color: rgba(12, 15, 74, 0.5);
    }
  }
  &__button.active {
    background: #FFA500;
    color: #FFF;
  }
}

@include media-sm-max-width() {
  .edit-property-details {
    min-width: 200px;
    &__content {
      gap: .7rem;
    }
    &__form {
      gap: .3rem;
      :deep(.el-form-item__label) {
        font-size: 12px;
      }
      .el-input, .el-select {
        height: 39px;

        :deep(.el-input__inner) {
          height: 39px;
        }
      }
    }
    &__lease-input-container {
      gap: .5rem;
    }
    &__label {
      font-size: 12px;
      line-height: 15px;
    }
  }
}
</style>

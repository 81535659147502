import { defineComponent } from 'vue';
import KYPWidgetContent from '@/core/components/common/widgets/contents/KYPWidgetContent.vue';
import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
export default defineComponent({
    props: ['cardHeight', 'property'],
    components: {
        DefaultCardSlot,
        KYPWidgetContent
    },
    data() {
    },
    computed: {},
    methods: {},
});

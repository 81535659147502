<template>
    <section class="log-component">
        <el-scrollbar class="log-component__scrollbar">
            <div class="log-component__content flex-column" v-for="(item, index) in groupedData" :key="index">
                <p class="activity-date default-text">{{index}}</p>
                <template v-if="canCreate">
                  <el-timeline>
                   <TimelineItem v-for="(timeline, i) in item" :key="i" :data="timeline" :isClickable="isClickable" @click="handleClick(timeline)"/>
                  </el-timeline>
                </template>
            </div>
        </el-scrollbar>
    </section>
</template>
<script>
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import TimelineItem from '@/core/components/ui/TimelineItem.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    TimelineItem
  },
  emits: ['handle-click'],
  props: {
    data: {
      type: Array,
      default: () => []
    },
    isClickable: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters(USERS_STORE, ['user', 'isHosted', 'user', 'wsRole', 'isWsAdmin']),
    groupedData() {
      const groups = {
        Today: [],
        Yesterday: [],
      };

      const now = moment();
      const today = now.clone().startOf('day');
      const yesterday = now.clone().subtract(1, 'days').startOf('day');

      this.data.forEach((item) => {
        const itemDate = moment(item.dateCreated);

        if (itemDate.isSame(today, 'day')) {
          groups.Today.push(item);
        } else if (itemDate.isSame(yesterday, 'day')) {
          groups.Yesterday.push(item);
        } else {
          const date = itemDate.format('Do MMM YYYY');
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(item);
        }
      });

      const sortedGroups = {};

      // Include "Today" and "Yesterday" categories if there's data
      if (groups.Today.length > 0) {
        sortedGroups.Today = groups.Today;
      }
      if (groups.Yesterday.length > 0) {
        sortedGroups.Yesterday = groups.Yesterday;
      }

      // Include other dates
      Object.keys(groups)
        .filter((key) => key !== 'Today' && key !== 'Yesterday')
        .sort((a, b) => moment(b, 'Do MMM YYYY').valueOf() - moment(a, 'Do MMM YYYY').valueOf())
        .forEach((key) => {
          sortedGroups[key] = groups[key];
        });

      return sortedGroups;
    }
  },
  methods: {
    handleClick(item) {
      this.$emit('handle-click', item);
    },
    canCreate() {
      const { wsRole, isWsAdmin } = this;
      let show = true;

      if (wsRole && !isWsAdmin) {
        show = !show;
      }

      return show;
    },
  },
});
</script>
<style lang="scss" scoped>
  .log-component {
    width: 100%;
    height: 100%;
    position: relative;
    &__scrollbar {
      .activity-date {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.25px;
        text-align: left;
      }
    }
    &__content {
      position: relative;
      width: 100%;
      gap: 1.5rem;
      .el-timeline {
        position: relative;
        padding-left: 65px;
      }
    }
  }
</style>

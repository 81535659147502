<template>
    <el-timeline-item type="primary" :timestamp="timeStamp" size="large" :class="{'cursor-pointer': isClickable}">
      <div class="item-image flex-row ai-c jc-c">
        <img :src="thumbnail(data)" alt="item-image">
      </div>
      <h4 v-if="!hasCustomMessage" class="item-message title default-text" v-html="content"></h4>
      <!-- <p v-if="!hasCustomMessage" class="item-message description default-text" v-html="data?.content"></p> -->
      <slot name="custom-message" v-else></slot>
    </el-timeline-item>
</template>
<script>
import moment from 'moment';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
  },
  props: {
    hasCustomMessage: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    },
    isClickable: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
    };
  },
  computed: {
    content() {
      return this.data?.title || this.data?.content;
    },

    timeStamp() {
      return moment(this.data?.dateCreated).format('HH:mm');
    },
  },
  methods: {
    thumbnail(data) {
      if (data?.thumbnail) {
        return data?.thumbnail;
      }
      /* eslint-disable global-require */
      return `${require('@/assets/images/logo/thumbnail-logo.jpg')}`;
      /* eslint-disable global-require */
    }
  },
});
</script>
<style lang="scss" scoped>
  .el-timeline-item {
    position: relative;
    top: -3px;
    padding-bottom: 25px;
    :deep(.el-timeline-item__timestamp) {
      font-family: Mulish;
      font-weight: 500;
      position: absolute;
      left: -60px;
      top: 4px;
      margin-top:0;
    }
    :deep(.el-timeline-item__tail) {
      top: 10px;
    }
    .item-image {
      width: 30px;
      height:30px;
      position: absolute;
      left: -10px;
      top: -6px;
      border-radius: 50px;
      background: rgba(42, 42, 42, 0.2);

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 50px;
      }
    }

    .item-message {
      line-height: 18px;
      letter-spacing: 0.23999999463558197px;
    }

    .item-message.description {
      font-size: 14px;
      font-weight: 500;
    }
    .item-message.title {
      font-size: 15px;
      font-weight: 600;
    }
  }
  .blue {
    color: #4F55F0 !important;
    text-decoration: none;
  }

  .yellow {
    color: #FFA500 !important;
  }
</style>

<template>
    <div class="kyp flex-column ai-c jc-sb">
      <div class="kyp__container">
        <inline-svg :src="require(`@/assets/images/pie-chart.svg`)"></inline-svg>
      </div>
      <div class="kyp__wrapper flex-row jc-fe">
        <ActionItemComponent
          :class="{'cursor-no-drop': isOutsideUk}"
          label="View score"
          fontColor="#4B4BFF"
          icon="right-arrow.svg"
          :isReverse="true"
          fontSize="14px"
          @call-to-action="routeToKyp"/>
      </div>
    </div>
</template>
<script>
import { NA_POSTCODE } from '@/core/components/property/constants';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';

export default {
  props: ['property'],
  components: {
    ActionItemComponent
  },
  data() {
    return {
      NA_POSTCODE
    };
  },
  created() {
  },
  computed: {
    isOutsideUk() {
      return this.property.postcode === NA_POSTCODE;
    }
  },
  methods: {
    routeToKyp() {
      if (this.isOutsideUk) {
        return;
      }
      this.$router.push({ name: 'property-details/kyp' });
    },
  },
};
</script>
<style lang="scss" scoped>
.kyp {
    height: 100%;

    &__container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    &__wrapper {
      gap: .5rem;
      width: 100%;
    }
    &__title {
        font-size: 14px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.5px;
    }
    &__description {
        font-size: 12px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.5px;
        color:rgba(12, 15, 74, 0.5);
    }

    &__score {
        font-size: 18px;
        font-weight: 700;
        line-height: 10px;
        letter-spacing: 0.25px;
        position: absolute;
        top: 52%;
        left: 48%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
}
</style>
